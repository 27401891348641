<template class="productionForm">
  <div>
    <p>{{ header.dairy }}</p>
    <p>{{ header.farm }}</p>
    <p>{{ header.status }}</p>
    <v-data-table-server :headers="fields" :fields="fields" :items="rows" :loading="loading" density="compact" :items-length="totalElements">
      <template v-slot:[`item.quantityCalculated`]="{ item }">
        {{ $formatNumber(item['quantityCalculated'], 1) }}
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
        {{ $formatNumber(item['quantity'], 1) }}
      </template>
      <template v-slot:[`item.modification`]="{ item }">{{ $moment(item.modification).format('DD.MM.YYYY HH:MM') }} </template>
      <template v-slot:[`body.append`]="{}" v-if="(productionFormRow.status === 'CLOSED' || productionFormRow.status === 'CLOSED_NO_CREDITOR') && correction">
        <tr>
          <td class="text-end" v-html="$t('production_form_row_detail_correction')"></td>
          <td>
            <vNumberField
              v-model="quantity"
              :rules="[$rules.isNumeric]"
              :precision="1"
              id="quantity"
              name="quantity"
              class="nomessage"
              background-color="#f1dcb0"
              filled
              density="compact"
            ></vNumberField>
          </td>
          <td class="text-end">
            <v-btn color="primary" @click="save()">
              <span v-html="$t('production_form_row_detail_save')" />
            </v-btn>
          </td>
          <td></td>
          <td></td>
        </tr>
      </template>
    </v-data-table-server>
    <rdhComponent
      :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
      :history_show="true"
      :history_url="baseURL"
      ref="rdh"
    ></rdhComponent>
  </div>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import vNumberField from '../../../components/v-number-field.vue'
import rdhComponent from '@/components/rdh/rdhComponent'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'productionFormRowDetails',
  components: {
    rdhComponent: rdhComponent,
    vNumberField
  },
  props: {
    productionForm: Object,
    productionFormRow: Object,
    correction: Boolean
  },
  data() {
    return {
      header: {
        dairy: '',
        farm: '',
        status: ''
      },
      totalElements: 0,
      rows: [],
      row: { id: 0, quantity: null },
      fields: <DTSHeader[]>[
        {
          title: this.$t('production_form_row_quantity'),
          key: 'quantityCalculated',
          sortable: false,
          align: 'end'
        },
        {
          title: this.$t('production_form_row_total'),
          key: 'quantity',
          sortable: false,
          align: 'end'
        },
        {
          title: this.$t('production_form_row_version_no'),
          key: 'versionNo',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('production_form_row_date'),
          key: 'modification',
          sortable: false
        },
        {
          title: this.$t('production_form_row_user'),
          key: 'modifierName',
          sortable: false
        }
      ],
      quantity: null,
      loading: false
    }
  },
  computed: {
    baseURL() {
      return '/productionForms/' + this.productionForm.id + '/' + this.productionFormRow.animalTypeId + '/' + this.productionFormRow.farmId
    }
  },
  methods: {
    async getRows() {
      this.loading = true
      let url = apiURL + this.baseURL + '/rows'

      try {
        const response = await this.axios.get(url)
        this.rows = response.data
        if (this.rows.length) {
          this.header['dairy'] =
            this.productionForm.dairyIdent +
            ', ' +
            this.productionForm.name1 +
            ' ' +
            this.productionForm.name2 +
            ', ' +
            this.rows[0]['animalType' + this.$getUpLangKey()]
          this.header['farm'] = this.rows[0]['farmAgisId'] + ', ' + this.rows[0]['farmerName1'] + ' ' + this.rows[0]['farmerName2']
          this.header['status'] = this.rows[0]['yearMonth'] + ', ' + this.productionFormRow.statusName
        } else {
          this.header['dairy'] = this.productionForm.dairyIdent + ', ' + this.productionForm.name1 + ' ' + this.productionForm.name2
          this.header['farm'] = ''
          this.header['status'] = ''
        }
        this.loading = false
        this.totalElements = response.data.length
      } catch (e) {
        showError(e)
      }
    },
    save() {
      const row = this.productionFormRow
      row.quantity = this.quantity
      this.quantity = null
      this.$emit('patch-quantity', row)
    }
  },
  watch: {
    productionFormRow: {
      handler() {
        this.getRows()
      }
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.getRows()
    })
  }
})
</script>
